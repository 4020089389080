// extracted by mini-css-extract-plugin
export var devopsAchievementsSection = "J_g8";
export var devopsBenefitSection = "J_gY";
export var devopsBusinessSection = "J_gW";
export var devopsDomainSection = "J_g4";
export var devopsExpertiseSection = "J_g3";
export var devopsProjLogoSection = "J_g7";
export var devopsProvideSection = "J_gZ";
export var devopsResultsSection = "J_g0";
export var devopsSuccessStoriesSection = "J_g5";
export var devopsTestimonialPostSection = "J_g1";
export var devopsToolkitSection = "J_g2";
export var devopsVideoReviewSection = "J_g9";
export var devopsWhyChooseSection = "J_g6";
export var devopsWhyNeedSection = "J_gX";